import React from 'react';
import './App.css';
import Header from './components/header/Header';


function App() {
  const handleSubmit = (event) => {
    event.preventDefault();

    const name = event.target.name.value;
    const prayerRequest = event.target.prayerRequest.value;
    
    const whatsappLink = `https://wa.me/554896805507?text=${encodeURIComponent(
      'Nome: ' + name + '\nPedido de Oração: ' + prayerRequest
    )}`;

    window.open(whatsappLink, '_blank');
  };

  return (
    <>
    <div className="container">
        
       <Header /> 

      <h1 className="text-3xl font-bold mt-2">Perfeita Paz</h1>
      <p>
        O projeto de evangelização "Perfeita Paz". Nosso objetivo é
        compartilhar a paz perfeita que vem de Jesus, trazendo conforto e
        esperança para aqueles que desejam experimentar 
        dessa paz que excede todo conhecimento. 
        <a href="https://www.bible.com/bible/2645/PHP.4.A21"> Leia em Filipenses 4:7 </a>
      </p>

         {/* <!-- Inclua o ícone SVG do WhatsApp --> */}
        <div className="flex items-center justify-center p-4">
        <a
            href="https://wa.me/554896805507" 
            target="_blank" 
            rel="noopener noreferrer"
            className="flex items-center p-3 bg-green-500 rounded-full text-white hover:bg-green-600 transition-colors"
        >
            <img
            src="/assets/images/icon-whatsapp.png"
            alt="WhatsApp Icon"
            className="w-6 h-6 mr-2"
            />
            <span className="text-sm font-medium">Fale Conosco</span>
        </a>
        </div>
    
      <h2 className="text-2xl font-bold mt-2">Pedido de Oração</h2>
      <p>
        Se você tem um pedido de oração, por favor, preencha o formulário
        abaixo. Queremos orar por você!
      </p>

      <form id="prayerForm" onSubmit={handleSubmit}>
        <input type="text" id="name" name="name" placeholder="Seu Nome" required />
        <input
          type="text"
          id="prayerRequest"
          name="prayerRequest"
          placeholder="Seu Pedido de Oração"
          required
        />
        <input type="submit" value="Enviar Pedido de Oração" />
      </form>
    </div>

    </>
  );
}

export default App;
