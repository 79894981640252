import React from 'react';
import logo from './images/logo-home.svg';


const Header = () => {
  return (
    <header>
      <nav className="flex justify-center h-42 w-auto items-center bg-gradient-to-r from-sky-500 to-indigo-500">
        <div className="relative w-52 h-52 sm:w-24 sm:h-24 md:w-52 md:h-52 rounded-full overflow-hidden border-2 border-slate-300 p-1 mt-2 mr-8 ml-4 mb-1">
         <a href='https://www.bible.com/bible/1608/GEN.1.ARA' target='_blank' rel="noreferrer" title='Leia a Bíblia'>                 
          <img
              src={logo}              
              alt="logo navigation"
              className="object-cover w-full h-full"
         />
         
         </a>         
      </div>
      </nav>
    </header>
  );
}
export default Header;
